import { ErrorMessage, Field, Form, Formik } from "formik";
import { Modal } from "react-bootstrap";
import { ICaste, initialFormData } from "..";
import axiosClient from "../../../../../utils/api/axiosClient";
import { getToken } from "../../../../../utils/functions";
import { IReligion } from "../../religion";
import { CasteSchema } from "../validation";

export default function FormModel(props: any) {
  const { setCastes, setInitial, modalShow, setModalShow } = props;
  const initial = props.initial as ICaste;
  const religions = props.religions as IReligion[];

  const handleSubmit = (values: ICaste) => {
    const headers = { Authorization: getToken() };
    const payload = {
      name: values.name,
      religionId: values.religionId,
    };
    if (initial.id) {
      axiosClient
        .put("v1/castes/" + initial.id, payload, { headers })
        .then((res) => {
          if (res.status === 200) setCastes(res.data);
        });
    } else {
      axiosClient.post("v1/castes", payload, { headers }).then((res) => {
        if (res.status === 200) setCastes(res.data);
      });
    }
    values.name = "";
    values.religionId = 0;
    setInitial(initialFormData);
  };

  return (
    <Modal
      show={modalShow}
      onHide={() => setModalShow(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add/Edit Caste
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initial}
          validationSchema={CasteSchema}
          onSubmit={handleSubmit}
        >
          {({
            touched,
            errors,
            isSubmitting,
            values,
            handleChange,
            handleBlur,
          }) => (
            <Form className="auth-form login-form">
              <div className="email mb-3">
                <label htmlFor="name">Select Religion</label>
                <select
                  name="religionId"
                  value={values.religionId}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`mt-2 form-control ${
                    touched.religionId && errors.religionId ? "is-invalid" : ""
                  }`}
                >
                  <option value="" label="Select a Religion">
                    Select a religion{" "}
                  </option>
                  {religions.map((religion, index) => {
                    return (
                      <option
                        key={index}
                        value={religion.id}
                        label={religion.name}
                      >
                        {religion.name}
                      </option>
                    );
                  })}
                </select>

                <ErrorMessage
                  component="div"
                  name="religionId"
                  className="invalid-feedback"
                />
              </div>
              <div className="email mb-3">
                <label htmlFor="name">Caste Name</label>
                <Field
                  id="name"
                  name="name"
                  values={initial.name}
                  placeholder="Enter Caste Name"
                  autoComplete="off"
                  className={`mt-2 form-control ${
                    touched.name && errors.name ? "is-invalid" : ""
                  }`}
                />

                <ErrorMessage
                  component="div"
                  name="name"
                  className="invalid-feedback"
                />
              </div>
              <div className="extra mt-3 row justify-content-between">
                <div className="col-6">
                  <div className="form-check">
                    <label className="form-check-label" htmlFor="status">
                      Is active ?
                    </label>
                    <input
                      id="status"
                      name="status"
                      className="form-check-input"
                      type="checkbox"
                      defaultValue=""
                    />
                  </div>
                </div>
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  className="btn app-btn-primary w-100 theme-btn mx-auto"
                >
                  SAVE
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}
