import { useEffect, useState } from "react";
import axiosClient from "../../../../utils/api/axiosClient";
import { getToken } from "../../../../utils/functions";
import FormModel from "./models/FormModel";

export interface IRaasi {
  id: number;
  name: string;
  isActive: boolean;
}

export const initialFormData: IRaasi = {
  id: 0,
  name: "",
  isActive: false,
};

function Raasi() {
  const [raasis, setRaasis] = useState<IRaasi[]>([]);
  const [initial, setInitial] = useState<IRaasi>(initialFormData);
  useEffect(() => {
    const headers = { Authorization: getToken() };
    axiosClient.get("v1/raasi", { headers }).then((res) => {
      if (res.status === 200) setRaasis(res.data);
    });
  }, []);

  const handleDelete = (id: number) => {
    const headers = { Authorization: getToken() };
    axiosClient.delete("v1/raasi/" + id, { headers }).then((res) => {
      if (res.status === 200) setRaasis(res.data);
    });
  };

  const handleEdit = (state: IRaasi) => {
    setInitial(state);
    setModalShow(true);
  };

  const handleCreate = () => {
    setInitial(initialFormData);
    setModalShow(true);
  };

  const [modalShow, setModalShow] = useState(false);

  return (
    <div className="app-content pt-3 p-md-3 p-lg-4">
      <div className="container-xl">
        <h1 className="app-page-title">Raasi</h1>
        <div className="row">
          <div className=" col-lg-12 ">
            <div className="card">
              <div className="card-header gutters-5">
                <h5 className="mb-md-0 h6">
                  All Raasi
                  <i
                    onClick={handleCreate}
                    className="fa fa-plus btn btn-sm pull-right"
                  ></i>
                </h5>
              </div>
              <div className="card-body">
                <table className="table aiz-table mb-0 footable footable-1 breakpoint-lg">
                  <thead>
                    <tr className="footable-header">
                      <th style={{ display: "table-cell" }}>#</th>
                      <th style={{ display: "table-cell" }}>Name</th>
                      <th style={{ display: "table-cell" }}>IsActives</th>
                      <th
                        className="text-right"
                        style={{ display: "table-cell", width: "20%" }}
                      >
                        Options
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {raasis.length ? (
                      raasis.map((raasi, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{raasi.name}</td>
                            <td>{raasi.isActive ? "TRUE" : "FALSE"}</td>
                            <td>
                              <i
                                className="fa fa-pencil btn btn-sm"
                                onClick={() => handleEdit(raasi)}
                              ></i>
                              <i
                                className="fa fa-trash btn btn-sm"
                                onClick={() => handleDelete(raasi.id)}
                              ></i>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr className="footable-empty text-center">
                        <td colSpan={3}>Nothing Found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <div className="aiz-pagination"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FormModel
        {...{
          setRaasis,
          initial,
          setInitial,
          modalShow,
          setModalShow,
        }}
      />
    </div>
  );
}

export default Raasi;
