import { Field, Form, Formik } from "formik";
import React from "react";
import { Modal } from "react-bootstrap";
import axiosClient from "../../../../../../utils/api/axiosClient";
import { getToken } from "../../../../../../utils/functions";
import { IUser } from "../../../../../sing-in/slice/types";
import { PaymentValidationSchema } from "../validators";

interface IInitialValues {
  userId: number;
  amount: number;
}

interface IViewModalProps {
  modalShow: boolean;
  user: IUser;
  updateModal: Function;
}

export default function ViewModal(props: IViewModalProps) {
  const { modalShow, user, updateModal } = props;

  const createOrder = (values: IInitialValues) => {
    const { userId, amount } = values;
    const headers = { Authorization: getToken() };
    const reqApi = `v1/orders/create-manual-order/${userId}/${amount}`;
    axiosClient.post(reqApi, {}, { headers }).then((res) => alert(res.data));
  };

  return (
    <>
      <Modal
        show={modalShow}
        onHide={() => updateModal({ modalShow: false, user })}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ fontSize: "14px" }}
          >
            Manual Order for {user?.firstName} {user?.lastName}
          </Modal.Title>
        </Modal.Header>

        <Formik
          initialValues={{ userId: user.id, amount: 0 }}
          onSubmit={createOrder}
          validationSchema={PaymentValidationSchema}
        >
          {({ errors }) => {
            return (
              <Form>
                <Modal.Body>
                  <div className="form-group">
                    <label>UserId</label>
                    <Field name="userId" className="form-control" disabled />
                    <span className="text-danger">{errors.userId}</span>
                  </div>
                  <div className="form-group">
                    <label>Amount</label>
                    <Field name="amount" className="form-control" />
                    <span className="text-danger">{errors.amount}</span>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="submit"
                    className="btn btn-sm btn-primary form-control"
                  >
                    Submit
                  </button>
                </Modal.Footer>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
}
