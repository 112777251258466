import { ErrorMessage, Field, Form, Formik } from "formik";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { initialFormData, ICity } from "..";
import axiosClient from "../../../../../utils/api/axiosClient";
import { getToken } from "../../../../../utils/functions";
import { ICountry } from "../../country";
import { IState } from "../../state";
import { CitySchema } from "../validation";

export default function FormModel(props: any) {
  const { setCities, setInitial, modalShow, setModalShow } = props;
  const initial = props.initial as ICity;
  const states = props.states as IState[];
  const countries = props.countries as ICountry[];

  const [tempStates, setTempState] = useState<IState[]>([]);

  const handleSubmit = (values: ICity) => {
    const headers = { Authorization: getToken() };
    const payload = {
      name: values.name,
      stateId: values.stateId,
      countryId: values.countryId,
    };
    if (initial.id) {
      axiosClient
        .put("v1/cities/" + initial.id, payload, { headers })
        .then((res) => {
          if (res.status === 200) setCities(res.data);
        });
    } else {
      axiosClient.post("v1/cities", payload, { headers }).then((res) => {
        if (res.status === 200) setCities(res.data);
      });
    }
    values.name = "";
    values.stateId = 0;
    values.countryId = 0;
    setInitial(initialFormData);
  };

  return (
    <Modal
      show={modalShow}
      onHide={() => setModalShow(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add/Edit State
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initial}
          validationSchema={CitySchema}
          onSubmit={handleSubmit}
        >
          {({
            touched,
            errors,
            isSubmitting,
            values,
            handleChange,
            handleBlur,
          }) => (
            <Form className="auth-form login-form">
              <div className="email mb-3">
                <label htmlFor="name">Select Country</label>
                <select
                  name="countryId"
                  value={values.countryId}
                  onChange={(e) => {
                    const countryId = parseInt(e?.target?.value);
                    const tempStates = states.filter(
                      (s) => s.countryId === countryId
                    );
                    setTempState(tempStates);
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                  className={`mt-2 form-control ${
                    touched.countryId && errors.countryId ? "is-invalid" : ""
                  }`}
                >
                  <option value="" label="Select a country">
                    Select a country{" "}
                  </option>
                  {countries.map((country, index) => {
                    return (
                      <option
                        key={index}
                        value={country.id}
                        label={country.name}
                      >
                        {country.name}
                      </option>
                    );
                  })}
                </select>

                <ErrorMessage
                  component="div"
                  name="countryId"
                  className="invalid-feedback"
                />
              </div>
              <div className="email mb-3">
                <label htmlFor="name">Select State</label>
                <select
                  name="stateId"
                  value={values.stateId}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`mt-2 form-control ${
                    touched.stateId && errors.stateId ? "is-invalid" : ""
                  }`}
                >
                  <option value="" label="Select a State">
                    Select a State{" "}
                  </option>
                  {tempStates.map((state, index) => {
                    return (
                      <option key={index} value={state.id} label={state.name}>
                        {state.name}
                      </option>
                    );
                  })}
                </select>

                <ErrorMessage
                  component="div"
                  name="stateId"
                  className="invalid-feedback"
                />
              </div>
              <div className="email mb-3">
                <label htmlFor="name">city Name</label>
                <Field
                  id="name"
                  name="name"
                  values={initial.name}
                  placeholder="Enter City Name"
                  autoComplete="off"
                  className={`mt-2 form-control ${
                    touched.name && errors.name ? "is-invalid" : ""
                  }`}
                />

                <ErrorMessage
                  component="div"
                  name="name"
                  className="invalid-feedback"
                />
              </div>
              <div className="extra mt-3 row justify-content-between">
                <div className="col-6">
                  <div className="form-check">
                    <label className="form-check-label" htmlFor="status">
                      Is active ?
                    </label>
                    <input
                      id="status"
                      name="status"
                      className="form-check-input"
                      type="checkbox"
                      defaultValue=""
                    />
                  </div>
                </div>
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  className="btn app-btn-primary w-100 theme-btn mx-auto"
                >
                  SAVE
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}
