import { Link } from "react-router-dom";

function Error() {
  return (
    <div className="app app-404-page">
      <div className="container mb-5">
        <div className="row">
          <div className="col-12 col-md-11 col-lg-7 col-xl-6 mx-auto">
            <div className="app-branding text-center mb-5">
              <a className="app-logo" href="index.html">
                <img
                  className="logo-icon me-2"
                  src="/assets/images/app-logo.svg"
                  alt="logo"
                />
                <span className="logo-text">PORTAL</span>
              </a>
            </div>
            {/*//app-branding*/}
            <div className="app-card p-5 text-center shadow-sm">
              <h1 className="page-title mb-4">
                404
                <br />
                <span className="font-weight-light">Page Not Found</span>
              </h1>
              <div className="mb-4">
                Sorry, we can't find the page you're looking for.
              </div>
              <Link className="btn app-btn-primary" to={"/admin"}>
                Go to home page
              </Link>
            </div>
          </div>
          {/*//col*/}
        </div>
        {/*//row*/}
      </div>
      {/*//container*/}
      <footer className="app-footer">
        <div className="container text-center py-3">
          {/*/* This template is free as long as you keep the footer attribution link. If you'd like to use the template without the attribution link, you can buy the commercial license via our website: themes.3rdwavemedia.com Thank you for your support. :) * /*/}
          <small className="copyright">
            Designed with <span className="sr-only">love</span>
            <i className="fas fa-heart" style={{ color: "#fb866a" }} /> by{" "}
            <a className="app-link" href="http://themes.3rdwavemedia.com">
              Satz Techno
            </a>{" "}
            for developers
          </small>
        </div>
      </footer>
      {/*//app-footer*/}
    </div>
  );
}

export default Error;
