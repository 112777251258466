import { Link } from "react-router-dom";
import Logo from "../../../../../assets/app-logo.svg";

function Sidebar() {
  const handleToggle = () => {
    const sidePanel = document.getElementById("app-sidepanel");
    if (sidePanel && sidePanel.classList.contains("sidepanel-visible")) {
      sidePanel.classList.remove("sidepanel-visible");
      sidePanel.classList.add("sidepanel-hidden");
    } else if (sidePanel) {
      sidePanel.classList.remove("sidepanel-hidden");
      sidePanel.classList.add("sidepanel-visible");
    }
  };
  return (
    <div id="app-sidepanel" className="app-sidepanel">
      <div id="sidepanel-drop" className="sidepanel-drop" />
      <div className="sidepanel-inner d-flex flex-column">
        <Link
          to="#"
          onClick={handleToggle}
          id="sidepanel-close"
          className="sidepanel-close d-xl-none"
        >
          ×
        </Link>
        <div className="app-branding">
          <Link className="app-logo" to={"/admin"}>
            <img className="logo-icon me-2" src={Logo} alt="logo" />
            <span className="logo-text">Admin</span>
          </Link>
        </div>
        <nav id="app-nav-main" className="app-nav app-nav-main flex-grow-1">
          <ul className="app-menu list-unstyled accordion" id="menu-accordion">
            <li className="nav-item">
              <Link className="nav-link active" to={"/admin"}>
                <span className="nav-icon">
                  <svg
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    className="bi bi-house-door"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M7.646 1.146a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 .146.354v7a.5.5 0 0 1-.5.5H9.5a.5.5 0 0 1-.5-.5v-4H7v4a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .146-.354l6-6zM2.5 7.707V14H6v-4a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5v4h3.5V7.707L8 2.207l-5.5 5.5z"
                    />
                    <path
                      fillRule="evenodd"
                      d="M13 2.5V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"
                    />
                  </svg>
                </span>
                <span className="nav-link-text">Dashboard</span>
              </Link>
            </li>
            {/*//nav-item*/}
            <li className="nav-item">
              {/*//Bootstrap Icons: https://icons.getbootstrap.com/ */}
              <Link className="nav-link" to={"/admin/packages"}>
                <span className="nav-icon">
                  <svg
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    className="bi bi-folder"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M9.828 4a3 3 0 0 1-2.12-.879l-.83-.828A1 1 0 0 0 6.173 2H2.5a1 1 0 0 0-1 .981L1.546 4h-1L.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3v1z" />
                    <path
                      fillRule="evenodd"
                      d="M13.81 4H2.19a1 1 0 0 0-.996 1.09l.637 7a1 1 0 0 0 .995.91h10.348a1 1 0 0 0 .995-.91l.637-7A1 1 0 0 0 13.81 4zM2.19 3A2 2 0 0 0 .198 5.181l.637 7A2 2 0 0 0 2.826 14h10.348a2 2 0 0 0 1.991-1.819l.637-7A2 2 0 0 0 13.81 3H2.19z"
                    />
                  </svg>
                </span>
                <span className="nav-link-text">Premium Packages</span>
              </Link>
              {/*//nav-link*/}
            </li>
            {/*//nav-item*/}
            <li className="nav-item">
              {/*//Bootstrap Icons: https://icons.getbootstrap.com/ */}
              <Link className="nav-link" to={"/admin/happy-stories"}>
                <span className="nav-icon">
                  <svg
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    className="bi bi-card-list"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M14.5 3h-13a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"
                    />
                    <path
                      fillRule="evenodd"
                      d="M5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 5 8zm0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5z"
                    />
                    <circle cx="3.5" cy="5.5" r=".5" />
                    <circle cx="3.5" cy={8} r=".5" />
                    <circle cx="3.5" cy="10.5" r=".5" />
                  </svg>
                </span>
                <span className="nav-link-text">Happy Stories</span>
              </Link>
              {/*//nav-link*/}
            </li>
            {/*//nav-item*/}
            <li className="nav-item has-submenu">
              {/*//Bootstrap Icons: https://icons.getbootstrap.com/ */}
              <Link
                className="nav-link submenu-toggle"
                to="#"
                data-bs-toggle="collapse"
                data-bs-target="#submenu-1"
                aria-expanded="false"
                aria-controls="submenu-1"
              >
                <span className="nav-icon">
                  {/*//Bootstrap Icons: https://icons.getbootstrap.com/ */}
                  <svg
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    className="bi bi-files"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4 2h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H4z"
                    />
                    <path d="M6 0h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6a1 1 0 0 0-1 1H4a2 2 0 0 1 2-2z" />
                  </svg>
                </span>
                <span className="nav-link-text">Website Setup</span>
                <span className="submenu-arrow">
                  <svg
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    className="bi bi-chevron-down"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                    />
                  </svg>
                </span>
                {/*//submenu-arrow*/}
              </Link>
              {/*//nav-link*/}
              <div
                id="submenu-1"
                className="collapse submenu submenu-1"
                data-bs-parent="#menu-accordion"
              >
                <ul className="submenu-list list-unstyled">
                  <li className="submenu-item">
                    <Link className="submenu-link" to={"/admin/website-setup"}>
                      Header
                    </Link>
                  </li>
                  <li className="submenu-item">
                    <Link className="submenu-link" to={"/admin/website-setup"}>
                      Footer
                    </Link>
                  </li>
                  <li className="submenu-item">
                    <Link className="submenu-link" to={"/admin/appearances"}>
                      Appearance
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
            {/*//nav-item*/}
            <li className="nav-item has-submenu">
              {/*//Bootstrap Icons: https://icons.getbootstrap.com/ */}
              <Link
                className="nav-link submenu-toggle"
                to="#"
                data-bs-toggle="collapse"
                data-bs-target="#submenu-2"
                aria-expanded="false"
                aria-controls="submenu-2"
              >
                <span className="nav-icon">
                  {/*//Bootstrap Icons: https://icons.getbootstrap.com/ */}
                  <svg
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    className="bi bi-columns-gap"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M6 1H1v3h5V1zM1 0a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1H1zm14 12h-5v3h5v-3zm-5-1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1h-5zM6 8H1v7h5V8zM1 7a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1H1zm14-6h-5v7h5V1zm-5-1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1h-5z"
                    />
                  </svg>
                </span>
                <span className="nav-link-text">Profile Attributes</span>
                <span className="submenu-arrow">
                  <svg
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    className="bi bi-chevron-down"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                    />
                  </svg>
                </span>
                {/*//submenu-arrow*/}
              </Link>
              {/*//nav-link*/}
              <div
                id="submenu-2"
                className="collapse submenu submenu-2"
                data-bs-parent="#menu-accordion"
              >
                <ul className="submenu-list list-unstyled">
                  <li className="submenu-item">
                    <Link className="submenu-link" to={"/admin/religions"}>
                      Religions
                    </Link>
                  </li>
                  <li className="submenu-item">
                    <Link className="submenu-link" to={"/admin/caste"}>
                      Caste
                    </Link>
                  </li>
                  <li className="submenu-item">
                    <Link className="submenu-link" to={"/admin/sub-caste"}>
                      Sub-Caste
                    </Link>
                  </li>
                  <li className="submenu-item">
                    <Link className="submenu-link" to={"/admin/countries"}>
                      Country
                    </Link>
                  </li>
                  <li className="submenu-item">
                    <Link className="submenu-link" to={"/admin/states"}>
                      State
                    </Link>
                  </li>
                  <li className="submenu-item">
                    <Link className="submenu-link" to={"/admin/cities"}>
                      City
                    </Link>
                  </li>
                  <li className="submenu-item">
                    <Link className="submenu-link" to={"/admin/on-behalf"}>
                      On Behalf
                    </Link>
                  </li>
                  <li className="submenu-item">
                    <Link className="submenu-link" to={"/admin/marital-status"}>
                      Marital Status
                    </Link>
                  </li>
                  <li className="submenu-item">
                    <Link className="submenu-link" to={"/admin/languages"}>
                      Language
                    </Link>
                  </li>
                  <li className="submenu-item">
                    <Link className="submenu-link" to={"/admin/professions"}>
                      Profession
                    </Link>
                  </li>
                  <li className="submenu-item">
                    <Link className="submenu-link" to={"/admin/qualifications"}>
                      Qualification
                    </Link>
                  </li>
                  <li className="submenu-item">
                    <Link className="submenu-link" to={"/admin/community-values"}>
                      Community Values
                    </Link>
                  </li>
                  <li className="submenu-item">
                    <Link className="submenu-link" to={"/admin/star"}>
                      Star
                    </Link>
                  </li>
                  <li className="submenu-item">
                    <Link className="submenu-link" to={"/admin/raasi"}>
                      Raasi
                    </Link>
                  </li>
                  <li className="submenu-item">
                    <Link className="submenu-link" to={"/admin/lagnam"}>
                      Lagnam
                    </Link>
                    <Link className="submenu-link" to={"/admin/paadham"}>
                      Paadham
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
            {/*//nav-item*/}
            <li className="nav-item has-submenu">
              {/*//Bootstrap Icons: https://icons.getbootstrap.com/ */}
              <Link
                className="nav-link submenu-toggle"
                to="#"
                data-bs-toggle="collapse"
                data-bs-target="#submenu-3"
                aria-expanded="false"
                aria-controls="submenu-3"
              >
                <span className="nav-icon">
                  {/*//Bootstrap Icons: https://icons.getbootstrap.com/ */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-people"
                    viewBox="0 0 16 16"
                  >
                    <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z" />
                  </svg>
                </span>
                <span className="nav-link-text">Members</span>
                <span className="submenu-arrow">
                  <svg
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    className="bi bi-chevron-down"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                    />
                  </svg>
                </span>
                {/*//submenu-arrow*/}
              </Link>
              {/*//nav-link*/}
              <div
                id="submenu-3"
                className="collapse submenu submenu-2"
                data-bs-parent="#menu-accordion"
              >
                <ul className="submenu-list list-unstyled">
                  <li className="submenu-item">
                    <Link className="submenu-link" to="/admin/free-members">
                      Free Members
                    </Link>
                  </li>
                  <li className="submenu-item">
                    <Link className="submenu-link" to="/admin/premium-members">
                      Premium Members
                    </Link>
                  </li>
                  <li className="submenu-item">
                    <Link className="submenu-link" to="/admin/expired-members">
                      Expired Members
                    </Link>
                  </li>
                  <li className="submenu-item">
                    <Link className="submenu-link" to="/admin/deleted-members">
                      Deleted Members
                    </Link>
                  </li>
                  <li className="submenu-item">
                    <Link className="submenu-link" to="/admin/reported-members">
                      Reported Members
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
            {/*//nav-item*/}
            <li className="nav-item has-submenu">
              {/*//Bootstrap Icons: https://icons.getbootstrap.com/ */}
              <Link
                className="nav-link submenu-toggle"
                to="#"
                data-bs-toggle="collapse"
                data-bs-target="#submenu-4"
                aria-expanded="false"
                aria-controls="submenu-4"
              >
                <span className="nav-icon">
                  {/*//Bootstrap Icons: https://icons.getbootstrap.com/ */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-people"
                    viewBox="0 0 16 16"
                  >
                    <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z" />
                  </svg>
                </span>
                <span className="nav-link-text">Staffs</span>
                <span className="submenu-arrow">
                  <svg
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    className="bi bi-chevron-down"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                    />
                  </svg>
                </span>
                {/*//submenu-arrow*/}
              </Link>
              {/*//nav-link*/}
              <div
                id="submenu-4"
                className="collapse submenu submenu-4"
                data-bs-parent="#menu-accordion"
              >
                <ul className="submenu-list list-unstyled">
                  <li className="submenu-item">
                    <Link className="submenu-link" to="/admin/staffs">
                      All Staffs
                    </Link>
                  </li>
                  <li className="submenu-item">
                    <Link className="submenu-link" to="/admin/roles">
                      Roles
                    </Link>
                  </li>
                  <li className="submenu-item">
                    <Link className="submenu-link" to="/admin/permission">
                      Permissions
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
            {/*//nav-item*/}
            <li className="nav-item has-submenu">
              {/*//Bootstrap Icons: https://icons.getbootstrap.com/ */}
              <Link
                className="nav-link submenu-toggle"
                to="#"
                data-bs-toggle="collapse"
                data-bs-target="#submenu-5"
                aria-expanded="false"
                aria-controls="submenu-5"
              >
                <span className="nav-icon">
                  {/*//Bootstrap Icons: https://icons.getbootstrap.com/ */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-wallet"
                    viewBox="0 0 16 16"
                  >
                    <path d="M0 3a2 2 0 0 1 2-2h13.5a.5.5 0 0 1 0 1H15v2a1 1 0 0 1 1 1v8.5a1.5 1.5 0 0 1-1.5 1.5h-12A2.5 2.5 0 0 1 0 12.5V3zm1 1.732V12.5A1.5 1.5 0 0 0 2.5 14h12a.5.5 0 0 0 .5-.5V5H2a1.99 1.99 0 0 1-1-.268zM1 3a1 1 0 0 0 1 1h12V2H2a1 1 0 0 0-1 1z" />
                  </svg>
                </span>
                <span className="nav-link-text">Wallet</span>
                <span className="submenu-arrow">
                  <svg
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    className="bi bi-chevron-down"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                    />
                  </svg>
                </span>
                {/*//submenu-arrow*/}
              </Link>
              {/*//nav-link*/}
              <div
                id="submenu-5"
                className="collapse submenu submenu-5"
                data-bs-parent="#menu-accordion"
              >
                <ul className="submenu-list list-unstyled">
                  <li className="submenu-item">
                    <Link
                      className="submenu-link"
                      to="/admin/wallet-transactions"
                    >
                      Transaction History
                    </Link>
                  </li>
                  <li className="submenu-item">
                    <Link className="submenu-link" to="/admin/recharge-request">
                      Recharge Requests
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
            {/*//nav-item*/}
          </ul>
          {/*//app-menu*/}
        </nav>
        {/*//app-nav*/}
        <div className="app-sidepanel-footer">
          <nav className="app-nav app-nav-footer">
            <ul className="app-menu footer-menu list-unstyled">
              <li className="nav-item">
                {/*//Bootstrap Icons: https://icons.getbootstrap.com/ */}
                <Link className="nav-link" to={"/admin/system"}>
                  <span className="nav-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-pc-display-horizontal"
                      viewBox="0 0 16 16"
                    >
                      <path d="M1.5 0A1.5 1.5 0 0 0 0 1.5v7A1.5 1.5 0 0 0 1.5 10H6v1H1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1h-5v-1h4.5A1.5 1.5 0 0 0 16 8.5v-7A1.5 1.5 0 0 0 14.5 0h-13Zm0 1h13a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .5-.5ZM12 12.5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm2 0a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0ZM1.5 12h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1ZM1 14.25a.25.25 0 0 1 .25-.25h5.5a.25.25 0 1 1 0 .5h-5.5a.25.25 0 0 1-.25-.25Z" />
                    </svg>
                  </span>
                  <span className="nav-link-text">System</span>
                </Link>
                {/*//nav-link*/}
              </li>
              {/*//nav-item*/}
            </ul>
            {/*//footer-menu*/}
          </nav>
        </div>
        {/*//app-sidepanel-footer*/}
      </div>
      {/*//sidepanel-inner*/}
    </div>
  );
}
export default Sidebar;
