import { useEffect, useState } from "react";
import axiosClient from "../../../../utils/api/axiosClient";
import { getToken } from "../../../../utils/functions";
import { ICountry } from "../country";
import FormModel from "./models/FormModel";

export interface IState {
  id: number;
  name: string;
  countryId: number;
}

export const initialFormData: IState = {
  id: 0,
  name: "",
  countryId: 0,
};

function State() {
  const [states, setStates] = useState<IState[]>([]);
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [initial, setInitial] = useState<IState>(initialFormData);

  useEffect(() => {
    const headers = { Authorization: getToken() };
    axiosClient.get("v1/states", { headers }).then((res) => {
      if (res.status === 200) setStates(res.data);
    });
    axiosClient.get("v1/countries", { headers }).then((res) => {
      if (res.status === 200) setCountries(res.data);
    });
  }, []);

  const handleDelete = (id: number) => {
    const headers = { Authorization: getToken() };
    axiosClient.delete("v1/states/" + id, { headers }).then((res) => {
      if (res.status === 200) setStates(res.data);
    });
  };

  const handleEdit = (state: IState) => {
    setInitial(state);
    setModalShow(true);
  };

  const handleCreate = () => {
    setInitial(initialFormData);
    setModalShow(true);
  };

  const [modalShow, setModalShow] = useState(false);

  return (
    <div className="app-content pt-3 p-md-3 p-lg-4">
      <div className="container-xl">
        <h1 className="app-page-title">State</h1>
        <div className="row">
          <div className=" col-lg-12 ">
            <div className="card">
              <div className="card-header gutters-5">
                <h5 className="mb-md-0 h6">
                  All States
                  <i
                    onClick={handleCreate}
                    className="fa fa-plus btn btn-sm pull-right"
                  ></i>
                </h5>
              </div>
              <div className="card-body">
                <table className="table aiz-table mb-0 footable footable-1 breakpoint-lg">
                  <thead>
                    <tr className="footable-header">
                      <th style={{ display: "table-cell" }}>#</th>
                      <th style={{ display: "table-cell" }}>Name</th>
                      <th style={{ display: "table-cell" }}>Country</th>
                      <th
                        className="text-right"
                        style={{ display: "table-cell", width: "20%" }}
                      >
                        Options
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {states.length ? (
                      states.map((state, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{state.name}</td>
                            <td>
                              {
                                countries.find((c) => c.id === state.countryId)
                                  ?.name
                              }
                            </td>
                            <td>
                              <i
                                className="fa fa-pencil btn btn-sm"
                                onClick={() => handleEdit(state)}
                              ></i>
                              <i
                                className="fa fa-trash btn btn-sm"
                                onClick={() => handleDelete(state.id)}
                              ></i>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr className="footable-empty text-center">
                        <td colSpan={3}>Nothing Found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <div className="aiz-pagination"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FormModel
        {...{
          setStates,
          initial,
          setInitial,
          modalShow,
          setModalShow,
          countries,
        }}
      />
    </div>
  );
}

export default State;
