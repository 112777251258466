import * as Yup from "yup";

export const CasteSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  religionId: Yup.number()
    .required("Religion is required")
    .test("test-religionId", function (value) {
      if (!value) {
        return this.createError({
          message: `Religion is required`,
          path: "religionId",
        });
      } else return true;
    }),
});
