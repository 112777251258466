import { HashRouter, Link, Route, Routes } from "react-router-dom";
import AdminPanel from "../pages/admin-panel";
import Account from "../pages/admin-panel/features/account";
import Cast from "../pages/admin-panel/features/cast";
import City from "../pages/admin-panel/features/city";
import CommunityValue from "../pages/admin-panel/features/community-value";
import Country from "../pages/admin-panel/features/country";
import Dashboard from "../pages/admin-panel/features/dashboard";
import Lagnam from "../pages/admin-panel/features/lagnam";
import Language from "../pages/admin-panel/features/language";
import MaritalStatus from "../pages/admin-panel/features/marital-status";
import {
  FreeMember,
  PremiumMember,
  ReportedMember,
  DeletedMember,
} from "../pages/admin-panel/features/members";
import ExpiredMembers from "../pages/admin-panel/features/members/Expired-member";
import OnBehalf from "../pages/admin-panel/features/on-behalf";
import Paadham from "../pages/admin-panel/features/paadham";
import Package from "../pages/admin-panel/features/package";
import Profession from "../pages/admin-panel/features/profession";
import Qualification from "../pages/admin-panel/features/qualification";
import Raasi from "../pages/admin-panel/features/raasi";
import Religion from "../pages/admin-panel/features/religion";
import Setting from "../pages/admin-panel/features/setting";
import Staffs from "../pages/admin-panel/features/staffs";
import Star from "../pages/admin-panel/features/star";
import State from "../pages/admin-panel/features/state";
import SubCast from "../pages/admin-panel/features/sub-cast";
import Error from "../pages/error";
import Forgot from "../pages/forgot";
import SignOut from "../pages/sign-out";
import SignUp from "../pages/sign-up";
import SignIn from "../pages/sing-in";

export const BRouter = () => {
  return (
    <HashRouter>
      <Routes>
        <Route
          path="/"
          element={
            <h1 style={{ textAlign: "center", marginTop: "20%" }}>
              Admin Panel <Link to={'/sign-in'}>Sign In</Link>
            </h1>
          }
        ></Route>
        <Route path="/admin" element={<AdminPanel />}>
          <Route path="" element={<Dashboard />} />
          <Route path="/admin/setting" element={<Setting />} />
          <Route path="/admin/account" element={<Account />} />
          <Route path="/admin/religions" element={<Religion />} />
          <Route path="/admin/caste" element={<Cast />} />
          <Route path="/admin/sub-caste" element={<SubCast />} />
          <Route path="/admin/countries" element={<Country />} />
          <Route path="/admin/states" element={<State />} />
          <Route path="/admin/cities" element={<City />} />
          <Route path="/admin/on-behalf" element={<OnBehalf />} />
          <Route path="/admin/marital-status" element={<MaritalStatus />} />
          <Route path="/admin/languages" element={<Language />}></Route>
          <Route path="/admin/professions" element={<Profession />}></Route>
          <Route path="/admin/packages" element={<Package />}></Route>
          <Route path="/admin/free-members" element={<FreeMember />}></Route>
          <Route
            path="/admin/community-values"
            element={<CommunityValue />}
          ></Route>
          <Route path="/admin/star" element={<Star />}></Route>
          <Route path="/admin/raasi" element={<Raasi />}></Route>
          <Route path="/admin/paadham" element={<Paadham />}></Route>
          <Route path="/admin/lagnam" element={<Lagnam />}></Route>
          <Route
            path="/admin/premium-members"
            element={<PremiumMember />}
          ></Route>
          <Route
            path="/admin/expired-members"
            element={<ExpiredMembers />}
          ></Route>
          <Route
            path="/admin/reported-members"
            element={<ReportedMember />}
          ></Route>
          <Route
            path="/admin/deleted-members"
            element={<DeletedMember />}
          ></Route>
          <Route
            path="/admin/qualifications"
            element={<Qualification />}
          ></Route>
          <Route path="/admin/staffs" element={<Staffs />} />
        </Route>
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/sign-out" element={<SignOut />} />
        <Route path="/forgot" element={<Forgot />} />
        <Route path="/*" element={<Error />} />
      </Routes>
    </HashRouter>
  );
};
