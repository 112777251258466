import { Outlet, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import { useOneTimeAction } from "../../utils/hooks";
import { authActions } from "../sing-in/slice";
import { authUser } from "../sing-in/slice/selector";
import Footer from "./features/common/footer";
import Navbar from "./features/common/navbar";
import Sidebar from "./features/common/sidebar";

function AdminPanel() {
  const user = useAppSelector(authUser);
  const navigator = useNavigate();
  const dispatch = useAppDispatch();

  useOneTimeAction(() => {
    if (localStorage.getItem("accessToken") && !user) {
      const token: string = localStorage.getItem("accessToken") || "no token";
      dispatch(authActions.validateReq({ token }));
    } else if (!localStorage.getItem("accessToken") && !user)
      navigator("/sign-in");
  });

  return (
    <div className="app">
      <header className="app-header fixed-top">
        <Navbar />
        <Sidebar />
      </header>
      <div className="app-wrapper">
        <Outlet />
        <Footer />
      </div>
    </div>
  );
}

export default AdminPanel;
