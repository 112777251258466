import * as Yup from "yup";

export const SubCasteSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  casteId: Yup.number()
    .required("Caste is required")
    .test("test-casteId", function (value) {
      if (!value) {
        return this.createError({
          message: `Caste is required`,
          path: "casteId",
        });
      } else return true;
    }),
  religionId: Yup.number()
    .required("Religiion is required")
    .test("test-religionId", function (value) {
      if (!value) {
        return this.createError({
          message: `Religion is required`,
          path: "religionId",
        });
      } else return true;
    }),
});
