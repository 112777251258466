import { useEffect, useState } from "react";
import axiosClient from "../../../../utils/api/axiosClient";
import { getToken } from "../../../../utils/functions";
import { IReligion } from "../religion";
import FormModel from "./models/FormModel";

export interface ICaste {
  id: number;
  name: string;
  religionId: number;
}

export const initialFormData: ICaste = {
  id: 0,
  name: "",
  religionId: 0,
};

function Cast() {
  const [castes, setCastes] = useState<ICaste[]>([]);
  const [religions, setReligions] = useState<IReligion[]>([]);
  const [initial, setInitial] = useState<ICaste>(initialFormData);

  useEffect(() => {
    const headers = { Authorization: getToken() };
    axiosClient.get("v1/castes", { headers }).then((res) => {
      if (res.status === 200) setCastes(res.data);
    });
    axiosClient.get("v1/religions", { headers }).then((res) => {
      if (res.status === 200) setReligions(res.data);
    });
  }, []);

  const handleDelete = (id: number) => {
    const headers = { Authorization: getToken() };
    axiosClient.delete("v1/castes/" + id, { headers }).then((res) => {
      if (res.status === 200) setCastes(res.data);
    });
  };

  const handleEdit = (cast: ICaste) => {
    setInitial(cast);
    setModalShow(true);
  };

  const handleCreate = () => {
    setInitial(initialFormData);
    setModalShow(true);
  };

  const [modalShow, setModalShow] = useState(false);

  return (
    <div className="app-content pt-3 p-md-3 p-lg-4">
      <div className="container-xl">
        <h1 className="app-page-title">Cast</h1>
        <div className="row">
          <div className=" col-lg-12 ">
            <div className="card">
              <div className="card-header gutters-5">
                <h5 className="mb-md-0 h6">
                  All Caste
                  <i
                    onClick={handleCreate}
                    className="fa fa-plus btn btn-sm pull-right"
                  ></i>
                </h5>
              </div>
              <div className="card-body">
                <table className="table aiz-table mb-0 footable footable-1 breakpoint-lg">
                  <thead>
                    <tr className="footable-header">
                      <th style={{ display: "table-cell" }}>#</th>
                      <th style={{ display: "table-cell" }}>Name</th>
                      <th style={{ display: "table-cell" }}>Religion</th>
                      <th
                        className="text-right"
                        style={{ display: "table-cell", width: "20%" }}
                      >
                        Options
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {castes.length ? (
                      castes.map((caste, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{caste.name}</td>
                            <td>
                              {
                                religions.find((r) => r.id === caste.religionId)
                                  ?.name
                              }
                            </td>
                            <td>
                              <i
                                className="fa fa-pencil btn btn-sm"
                                onClick={() => handleEdit(caste)}
                              ></i>
                              <i
                                className="fa fa-trash btn btn-sm"
                                onClick={() => handleDelete(caste.id)}
                              ></i>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr className="footable-empty text-center">
                        <td colSpan={3}>Nothing Found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <div className="aiz-pagination"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FormModel
        {...{
          setCastes,
          initial,
          setInitial,
          modalShow,
          setModalShow,
          religions,
        }}
      />
    </div>
  );
}

export default Cast;
