import axios, { AxiosResponse, InternalAxiosRequestConfig } from "axios";

var currentRequestCount = 0;
let previouseRequestCount = 0;
let loader = document.getElementById("loader");

setInterval(() => {
  if (currentRequestCount !== previouseRequestCount && loader) {
    if (currentRequestCount > 0) loader.style.display = "block";
    else loader.style.display = "none";
    previouseRequestCount = currentRequestCount;
  }
}, 100);

const axiosClient = axios.create({
  baseURL: "https://api.indianyadavs.com/api",
  headers: { "Content-Type": "application/json" },
});

// Interceptors
axiosClient.interceptors.request.use(
  function (config: InternalAxiosRequestConfig) {
    // Do something before request is sent
    currentRequestCount++;
    return config;
  },
  function (error) {
    // Do something with request error
    currentRequestCount--;
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosClient.interceptors.response.use(
  function (response: AxiosResponse) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    currentRequestCount--;
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    currentRequestCount--;
    return Promise.reject(error);
  }
);

export default axiosClient;
