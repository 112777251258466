import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/app-logo.svg";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { LoginSchema } from "./validation";
import { ISignInPayload, IUser } from "./slice/types";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import { authError, authUser } from "./slice/selector";
import { authActions } from "./slice";
import { useEffect } from "react";

function SignIn() {
  const dispatch = useAppDispatch();
  const navigator = useNavigate();
  const user: IUser | null = useAppSelector(authUser);
  const error = useAppSelector(authError);

  useEffect(() => {
    if (user) {
      if (user.roles.includes("ROLE_ADMIN")) {
        localStorage.setItem("accessToken", "Bearer " + user.accessToken);
        navigator("/admin");
      } else {
        navigator("/");
      }
    }
    if (localStorage.getItem("accessToken") && !user) {
      const token: string = localStorage.getItem("accessToken") || "no token";
      dispatch(authActions.validateReq({ token }));
    }
  }, [user, navigator, dispatch]);

  const handleFromSubmit = (values: ISignInPayload) => {
    dispatch(authActions.signInReq(values));
  };

  return (
    <div className="app app-login p-0">
      <div className="row g-0 app-auth-wrapper">
        <div className="col-12 col-md-7 col-lg-6 auth-main-col text-center p-5">
          <div className="d-flex flex-column align-content-end">
            <div className="app-auth-body mx-auto">
              <div className="app-auth-branding mb-4">
                <a className="app-logo" href="index.html">
                  <img className="logo-icon me-2" src={logo} alt="logo" />
                </a>
              </div>
              <h2 className="auth-heading text-center mb-5">
                Log in to Portal
              </h2>
              {error && (
                <div className="alert alert-danger">{error.message}</div>
              )}
              <div className="auth-form-container text-start">
                <Formik
                  initialValues={{ username: "", password: "", deviceType:'WEB' }}
                  validationSchema={LoginSchema}
                  onSubmit={handleFromSubmit}
                >
                  {({ touched, errors, isSubmitting, values }) => (
                    <Form className="auth-form login-form">
                      <div className="email mb-3">
                        <label className="sr-only" htmlFor="mobile">
                          Username
                        </label>
                        <Field
                          id="mobile"
                          type="text"
                          name="username"
                          placeholder="Enter Mobile"
                          autoComplete="off"
                          className={`mt-2 form-control ${
                            touched.username && errors.username
                              ? "is-invalid"
                              : ""
                          }`}
                        />

                        <ErrorMessage
                          component="div"
                          name="username"
                          className="invalid-feedback"
                        />
                      </div>

                      <div className="password mb-3">
                        <label className="sr-only" htmlFor="password">
                          Password
                        </label>
                        <Field
                          id="password"
                          type="password"
                          name="password"
                          placeholder="Enter password"
                          className={`mt-2 form-control ${
                            touched.password && errors.password
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="password"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="extra mt-3 row justify-content-between">
                        <div className="col-6">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue=""
                              id="RememberPassword"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="RememberPassword"
                            >
                              Remember me
                            </label>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="forgot-password text-end">
                            <Link to={"/forgot"}>Forgot password?</Link>
                          </div>
                        </div>
                      </div>
                      <div className="text-center">
                        <button
                          type="submit"
                          className="btn app-btn-primary w-100 theme-btn mx-auto"
                        >
                          Log In
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
                <div className="auth-option text-center pt-5">
                  No Account? Sign up{" "}
                  <Link className="text-link" to={"/sign-up"}>
                    here
                  </Link>
                  .
                </div>
              </div>
            </div>
            <footer className="app-auth-footer">
              <div className="container text-center py-3">
                <small className="copyright">
                  Designed with <span className="sr-only">love</span>
                  <i
                    className="fas fa-heart"
                    style={{ color: "#fb866a" }}
                  /> by{" "}
                  <a className="app-link" href="http://themes.3rdwavemedia.com">
                    Satz Techno
                  </a>{" "}
                  for developers
                </small>
              </div>
            </footer>
          </div>
        </div>
        <div className="col-12 col-md-5 col-lg-6 h-100 auth-background-col">
          <div className="auth-background-holder"></div>
          <div className="auth-background-mask" />
          <div className="auth-background-overlay p-3 p-lg-5">
            <div className="d-flex flex-column align-content-end h-100">
              <div className="h-100" />
              <div className="overlay-content p-3 p-lg-4 rounded">
                <h5 className="mb-3 overlay-title">
                  Explore Portal Admin Template
                </h5>
                <div>
                  Portal is a free Bootstrap 5 admin dashboard template. You can
                  download and view the template license{" "}
                  <a href="https://themes.3rdwavemedia.com/bootstrap-templates/admin-dashboard/portal-free-bootstrap-admin-dashboard-template-for-developers/">
                    here
                  </a>
                  .
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
