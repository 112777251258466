import { useEffect, useRef } from "react";

export const useComponentWillMount = (cb: Function) => {
  const willMount = useRef(true);
  if (willMount.current) cb();
  willMount.current = false;
};

export const useComponentDidMount = (cb: Function) => useEffect(() => cb());

export const useComponentWillUnMount = (cb: Function) => {
  const willUnmount = () => cb();
  useEffect(() => willUnmount);
};

export const useOneTimeAction = (cb: Function) => {
  const willMount = useRef(true);
  const oneTime = () => {
    if (willMount.current) cb();
    willMount.current = false;
  };
  useEffect(() => oneTime);
};
