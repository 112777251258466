import { call, put, takeLatest } from "redux-saga/effects";
import authApi from "../../../utils/api/authApi";
import { PayloadAction } from "@reduxjs/toolkit";
import { ISignInPayload, IUser, IVerifyTokenPayload } from "./types";
import { authActions } from ".";
import { IError, IResponse } from "../../../utils/types";

function* signIn(action: PayloadAction<ISignInPayload>) {
  try {
    const response: IResponse<IUser> = yield call(
      authApi.signIn,
      action.payload
    );
    if (response.status === 200) {
      const user: IUser = response.data;
      yield put(authActions.signInRes(user));
    } else {
      const error: IError = {
        message: "Somthig went wrong",
        code: response.status,
      };
      yield put(authActions.signInErr(error));
    }
  } catch (error: any) {
    yield put(authActions.signInErr(error));
  }
}

function* verifyToken(action: PayloadAction<IVerifyTokenPayload>) {
  try {
    const response: IResponse<IUser> = yield call(
      authApi.validatetoken,
      action.payload
    );
    if (response.status === 200) {
      const user: IUser = response.data;
      yield put(authActions.validateRes(user));
    } else {
      const error: IError = {
        message: "Somthig went wrong",
        code: response.status,
      };
      yield put(authActions.validateErr(error));
    }
  } catch (error: any) {
    yield put(authActions.validateErr(error));
  }
}

export default function* authSaga() {
  yield takeLatest(authActions.signInReq.type, signIn);
  yield takeLatest(authActions.validateReq.type, verifyToken);
}
