import DataTable from "../../../../../components/user-data-table";

export default function FreeMember() {
  
  return (
    <>
      <div className="app-content pt-3 p-md-3 p-lg-4">
        <DataTable api="getPremiumMembers" title="Premium Members" />
      </div>
    </>
  );
}
