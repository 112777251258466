import { ErrorMessage, Field, Form, Formik } from "formik";
import { Modal } from "react-bootstrap";
import { initialFormData, IPackage } from "..";
import axiosClient from "../../../../../utils/api/axiosClient";
import { getToken } from "../../../../../utils/functions";
import { PackageSchema } from "../validation";

export default function FormModel(props: any) {
  const { setPackages, initial, setInitial, modalShow, setModalShow } = props;
  const { communityValues } = props;

  const handleSubmit = (values: IPackage) => {
    const headers = { Authorization: getToken() };
    const formData = {
      name: values.name,
      expressInterest: values.expressInterest,
      photoGallery: values.photoGallery,
      contact: values.contact,
      autoProfileMatch: values.autoProfileMatch,
      price: values.price,
      validity: values.validity,
      image: values.image,
      status: values.status,
      gender: values.gender,
      communityValueId: values.communityValueId,
    };
    if (initial.id) {
      axiosClient
        .put("v1/packages/" + initial.id, formData, { headers })
        .then((res) => {
          if (res.status === 200) setPackages(res.data);
        });
    } else {
      axiosClient.post("v1/packages", formData, { headers }).then((res) => {
        if (res.status === 200) setPackages(res.data);
      });
    }
    setInitial(initialFormData);
  };

  return (
    <Modal
      show={modalShow}
      onHide={() => setModalShow(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Package</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initial}
          validationSchema={PackageSchema}
          onSubmit={handleSubmit}
        >
          {({ touched, errors, handleChange, values, handleBlur }) => (
            <Form className="auth-form login-form">
              <div className="email mb-3">
                <label htmlFor="name">Package Name</label>
                <Field
                  id="name"
                  name="name"
                  value={values.name}
                  placeholder="Enter Name"
                  autoComplete="off"
                  className={`mt-2 form-control ${
                    touched.name && errors.name ? "is-invalid" : ""
                  }`}
                />
                <ErrorMessage
                  component="div"
                  name="name"
                  className="invalid-feedback"
                />
              </div>
              <div className="email mb-3">
                <label htmlFor="gender">Gender</label>
                <select
                  id="gender"
                  name="gender"
                  value={values.gender}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`mt-2 form-control ${
                    touched.gender && errors.gender ? "is-invalid" : ""
                  }`}
                >
                  <option value={""}>Select Gender</option>
                  <option value={"MALE"}>MALE</option>
                  <option value={"FEMALE"}>FEMALE</option>
                </select>
                <ErrorMessage
                  component="div"
                  name="gender"
                  className="invalid-feedback"
                />
              </div>
              <div className="email mb-3">
                <label htmlFor="communityValueId">Community Value</label>
                <select
                  id="communityValueId"
                  name="communityValueId"
                  value={values?.communityValueId}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`mt-2 form-control ${
                    touched.communityValueId && errors.communityValueId
                      ? "is-invalid"
                      : ""
                  }`}
                >
                  <option value={""}>Select Community Value</option>
                  {communityValues.map((cv: any, index: any) => {
                    return (
                      <option key={index} value={cv.id}>
                        {cv.name}
                      </option>
                    );
                  })}
                </select>
                <ErrorMessage
                  component="div"
                  name="communityValueId"
                  className="invalid-feedback"
                />
              </div>
              <div className="email mb-3">
                <label htmlFor="expressInterest">No.of Interest</label>
                <Field
                  id="expressInterest"
                  name="expressInterest"
                  type="number"
                  values={values.expressInterest}
                  placeholder="Enter No of interest"
                  autoComplete="off"
                  className={`mt-2 form-control ${
                    touched.expressInterest && errors.expressInterest
                      ? "is-invalid"
                      : ""
                  }`}
                />

                <ErrorMessage
                  component="div"
                  name="expressInterest"
                  className="invalid-feedback"
                />
              </div>
              <div className="email mb-3">
                <label htmlFor="photoGallery">No.of Gallery</label>
                <Field
                  id="photoGallery"
                  name="photoGallery"
                  type="number"
                  values={values.photoGallery}
                  placeholder="Enter No of Gallery"
                  autoComplete="off"
                  className={`mt-2 form-control ${
                    touched.photoGallery && errors.photoGallery
                      ? "is-invalid"
                      : ""
                  }`}
                />

                <ErrorMessage
                  component="div"
                  name="photoGallery"
                  className="invalid-feedback"
                />
              </div>
              <div className="email mb-3">
                <label htmlFor="contact">No.of Contact</label>
                <Field
                  id="contact"
                  name="contact"
                  type="number"
                  values={values.contact}
                  placeholder="Enter No of Gallery"
                  autoComplete="off"
                  className={`mt-2 form-control ${
                    touched.contact && errors.contact ? "is-invalid" : ""
                  }`}
                />

                <ErrorMessage
                  component="div"
                  name="contact"
                  className="invalid-feedback"
                />
              </div>
              <div className="extra mt-3 row justify-content-between">
                <div className="col-6">
                  <div className="form-check">
                    <label
                      className="form-check-label"
                      htmlFor="autoProfileMatch"
                    >
                      Is auto profile match ?
                    </label>
                    <input
                      id="autoProfileMatch"
                      name="autoProfileMatch"
                      className="form-check-input"
                      type="checkbox"
                      onChange={handleChange}
                      defaultChecked={values.autoProfileMatch}
                    />
                  </div>
                </div>
              </div>
              <div className="email mb-3">
                <label htmlFor="price">Price</label>
                <Field
                  id="price"
                  name="price"
                  type="number"
                  values={values.price}
                  placeholder="Enter Price"
                  autoComplete="off"
                  className={`mt-2 form-control ${
                    touched.price && errors.price ? "is-invalid" : ""
                  }`}
                />

                <ErrorMessage
                  component="div"
                  name="price"
                  className="invalid-feedback"
                />
              </div>
              <div className="email mb-3">
                <label htmlFor="validity">Validity</label>
                <Field
                  id="validity"
                  name="validity"
                  type="number"
                  values={values.validity}
                  placeholder="Enter Validity days"
                  autoComplete="off"
                  className={`mt-2 form-control ${
                    touched.validity && errors.validity ? "is-invalid" : ""
                  }`}
                />

                <ErrorMessage
                  component="div"
                  name="validity"
                  className="invalid-feedback"
                />
              </div>
              <div className="email mb-3">
                <label htmlFor="image">Image</label>
                <Field
                  id="image"
                  name="image"
                  values={values.validity}
                  placeholder="Enter image"
                  autoComplete="off"
                  className={`mt-2 form-control ${
                    touched.image && errors.image ? "is-invalid" : ""
                  }`}
                />

                <ErrorMessage
                  component="div"
                  name="image"
                  className="invalid-feedback"
                />
              </div>
              <div className="extra mt-3 row justify-content-between">
                <div className="col-6">
                  <div className="form-check">
                    <label className="form-check-label" htmlFor="status">
                      Is active ?
                    </label>
                    <input
                      id="status"
                      name="status"
                      className="form-check-input"
                      type="checkbox"
                      onChange={handleChange}
                      defaultChecked={values.status}
                    />
                  </div>
                </div>
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  className="btn app-btn-primary w-100 theme-btn mx-auto"
                >
                  SAVE
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}
