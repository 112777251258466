import { ErrorMessage, Field, Form, Formik } from "formik";
import { Modal } from "react-bootstrap";
import axiosClient from "../../../../../utils/api/axiosClient";
import { getToken } from "../../../../../utils/functions";
import { CommunityValueSchema } from "../validation";

export default function FormModel(props: any) {
  const { setReligions, initial, setInitial, modalShow, setModalShow } = props;

  const handleSubmit = (values: { name: string }) => {
    const headers = { Authorization: getToken() };
    // create
    if (initial.id) {
      axiosClient
        .put(
          "v1/community-values/" + initial.id,
          { name: values.name },
          { headers }
        )
        .then((res) => {
          if (res.status === 200) setReligions(res.data);
        });
    } else {
      axiosClient
        .post("v1/community-values", { name: values.name }, { headers })
        .then((res) => {
          if (res.status === 200) setReligions(res.data);
        });
    }
    values.name = "";
    setInitial({ id: 0, name: "" });
  };

  return (
    <Modal
      show={modalShow}
      onHide={() => setModalShow(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Edit Community Value
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initial}
          validationSchema={CommunityValueSchema}
          onSubmit={handleSubmit}
        >
          {({ touched, errors, isSubmitting, values }) => (
            <Form className="auth-form login-form">
              <div className="email mb-3">
                <label htmlFor="name">Community Value Name</label>
                <Field
                  id="name"
                  name="name"
                  values={initial.name}
                  placeholder="Enter Name"
                  autoComplete="off"
                  className={`mt-2 form-control ${
                    touched.name && errors.name ? "is-invalid" : ""
                  }`}
                />

                <ErrorMessage
                  component="div"
                  name="name"
                  className="invalid-feedback"
                />
              </div>
              <div className="extra mt-3 row justify-content-between">
                <div className="col-6">
                  <div className="form-check">
                    <label className="form-check-label" htmlFor="isActive">
                      Is active ?
                    </label>
                    <input
                      id="isActive"
                      name="isActive"
                      className="form-check-input"
                      type="checkbox"
                      defaultValue=""
                    />
                  </div>
                </div>
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  className="btn app-btn-primary w-100 theme-btn mx-auto"
                >
                  SAVE
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}
