import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IError } from "../../../utils/types";
import {
  IAuthInitialState,
  ISignInPayload,
  IUser,
  IVerifyTokenPayload,
} from "./types";

export const initialState: IAuthInitialState = {
  loading: false,
  user: null,
  error: null,
};

const authSlice = createSlice({
  name: "authUser",
  initialState,
  reducers: {
    signInReq: (
      state: IAuthInitialState,
      actions: PayloadAction<ISignInPayload>
    ) => {
      state.loading = true;
      state.error = null;
    },
    signInRes: (state: IAuthInitialState, actions: PayloadAction<IUser>) => {
      state.user = actions.payload;
      state.loading = false;
      state.error = null;
    },
    signInErr: (state: IAuthInitialState, actions: PayloadAction<IError>) => {
      state.loading = false;
      state.error = actions.payload;
    },
    validateReq: (
      state: IAuthInitialState,
      actions: PayloadAction<IVerifyTokenPayload>
    ) => {
      state.loading = true;
      state.error = null;
    },
    validateRes: (state: IAuthInitialState, actions: PayloadAction<IUser>) => {
      state.user = actions.payload;
      state.loading = false;
      state.error = null;
    },
    validateErr: (state: IAuthInitialState, actions: PayloadAction<IError>) => {
      localStorage.removeItem("accessToken");
      state.loading = false;
      state.error = actions.payload;
    },
    logout: (state: IAuthInitialState) => {
      localStorage.removeItem("accessToken");
      state.loading = false;
      state.error = null;
      state.user = null;
    },
  },
});

export const authActions = authSlice.actions;
export const authReducer = authSlice.reducer;
export default authSlice;
