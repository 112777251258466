import React, { useState } from "react";
import { Table } from "react-bootstrap";
import axiosClient from "../../../../utils/api/axiosClient";
import { getToken } from "../../../../utils/functions";
import { useOneTimeAction } from "../../../../utils/hooks";

export default function Staffs() {
  const [staffs, setstaffs] = useState([]);

  const getStaffList = () => {
    const headers = { Authorization: getToken() };
    axiosClient.get("v1/users/getStaffList", { headers }).then((res) => {
      if (res.status === 200) setstaffs(res.data);
    });
  };

  useOneTimeAction(getStaffList);

  return (
    <div className="app-content pt-3 p-md-3 p-lg-4">
      <div className="container-xl">
        <div className="row">
          <div className=" col-lg-12 ">
            <div className="card">
              <div className="card-header gutters-5">
                <h5 className="mb-md-0 h6">
                  All Staffs
                  <i className="fa fa-plus btn btn-sm pull-right"></i>
                </h5>
              </div>
              <div className="card-body">
                <Table>
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Name</th>
                      <th>Mobile</th>
                      <th>Roles</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {staffs.map((s: any, i) => {
                      return (
                        <tr key={i}>
                          <td>{s.id}</td>
                          <td>
                            {s.firstName} {s.lastName}
                          </td>
                          <td>{s.mobile}</td>
                          <td style={{ fontSize: "8px" }}>
                            {s.roles.map((r: any, j: number) => {
                              return (
                                <div key={j}>
                                  <b>{r.name}</b>
                                </div>
                              );
                            })}
                          </td>
                          <td>
                            <i className="fa fa-pencil btn btn-sm"></i>
                            <i className="fa fa-trash btn btn-sm"></i>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
