import { useEffect, useState } from "react";
import axiosClient from "../../../../utils/api/axiosClient";
import { getToken } from "../../../../utils/functions";
import FormModel from "./models/FormModel";

export interface IPackage {
  id: number;
  name: string;
  expressInterest: number;
  photoGallery: number;
  contact: number;
  autoProfileMatch: boolean;
  price: number;
  validity: number;
  image: string;
  status: boolean;
  gender: string;
  communityValueId: number;
}

export const initialFormData = {
  id: 0,
  name: "",
  expressInterest: 0,
  photoGallery: 0,
  contact: 0,
  autoProfileMatch: false,
  price: 0,
  validity: 0,
  image: "",
  status: true,
  gender: "",
  communityValueId: 0,
};

function Package() {
  const [packages, setPackages] = useState<IPackage[]>([]);
  const [initial, setInitial] = useState<IPackage>(initialFormData);
  const [communityValues, setCommunityValues] = useState<any>([]);

  useEffect(() => {
    const headers = { Authorization: getToken() };
    axiosClient.get("v1/packages", { headers }).then((res) => {
      if (res.status === 200) setPackages(res.data);
    });
    axiosClient.get("v1/community-values", { headers }).then((res) => {
      if (res.status === 200) setCommunityValues(res.data);
    });
  }, []);

  const handleDelete = (id: number) => {
    const headers = { Authorization: getToken() };
    axiosClient.delete("v1/packages/" + id, { headers }).then((res) => {
      if (res.status === 200) setPackages(res.data);
    });
  };

  const handleEdit = (exitingPackage: any) => {
    exitingPackage.communityValueId = exitingPackage.communityValue.id;
    setInitial(exitingPackage);
    setModalShow(true);
  };

  const handleCreate = () => {
    setInitial(initialFormData);
    setModalShow(true);
  };

  const [modalShow, setModalShow] = useState(false);

  return (
    <div className="app-content pt-3 p-md-3 p-lg-4">
      <div className="container-xl">
        <h1 className="app-page-title">Package</h1>
        <div className="row">
          <div className=" col-lg-12 ">
            <div className="card">
              <div className="card-header gutters-5">
                <h5 className="mb-md-0 h6">
                  All Packages
                  <i
                    onClick={handleCreate}
                    className="fa fa-plus btn btn-sm pull-right"
                  ></i>
                </h5>
              </div>
              <div className="card-body">
                <table className="table aiz-table mb-0 footable footable-1 breakpoint-lg">
                  <thead>
                    <tr className="footable-header">
                      <th style={{ display: "table-cell" }}>#</th>
                      <th style={{ display: "table-cell" }}>Name</th>
                      <th style={{ display: "table-cell" }}>Interest</th>
                      <th style={{ display: "table-cell" }}>Gallery</th>
                      <th style={{ display: "table-cell" }}>Contact</th>
                      <th style={{ display: "table-cell" }}>Profile Match</th>
                      <th style={{ display: "table-cell" }}>Price</th>
                      <th style={{ display: "table-cell" }}>Validity</th>
                      <th style={{ display: "table-cell" }}>Image</th>
                      <th style={{ display: "table-cell" }}>isActive</th>
                      <th
                        className="text-right"
                        style={{ display: "table-cell", width: "20%" }}
                      >
                        Options
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {packages.length ? (
                      packages.map((pack, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{pack.name}</td>
                            <td>{pack.expressInterest}</td>
                            <td>{pack.photoGallery}</td>
                            <td>{pack.contact}</td>
                            <td>{pack.autoProfileMatch ? "TRUE" : "FALSE"}</td>
                            <td>{pack.price}</td>
                            <td>{pack.validity}</td>
                            <td>{pack.image}</td>
                            <td>{pack.status ? "TRUE" : "FALSE"}</td>
                            <td>
                              <i
                                className="fa fa-pencil btn btn-sm"
                                onClick={() => handleEdit(pack)}
                              ></i>
                              <i
                                className="fa fa-trash btn btn-sm"
                                onClick={() => handleDelete(pack.id)}
                              ></i>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr className="footable-empty text-center">
                        <td colSpan={11}>Nothing Found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <div className="aiz-pagination"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FormModel
        {...{
          setPackages,
          initial,
          setInitial,
          modalShow,
          setModalShow,
          communityValues,
        }}
      />
    </div>
  );
}

export default Package;
