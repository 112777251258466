import * as React from "react";
import {
  DataGrid,
  GridColDef,
  GridDensity,
  GridFilterItem,
  GridFilterModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { Paper, Switch, Typography } from "@mui/material";
import { getToken } from "../../utils/functions";
import axiosClient from "../../utils/api/axiosClient";
import {
  defaultFilterModel,
  defaultPageModel,
  pageSizeOptions,
} from "../../utils/constant";
import moment from "moment";
import ViewModal from "../../pages/admin-panel/features/members/premium-member/modals/ViewModal";
import { IUser } from "../../pages/sing-in/slice/types";


function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

interface IUserDataTableProps {
  api: string;
  title: string;
}

export default function UserDataTable(props: IUserDataTableProps) {
  const { api, title } = props;
  const [pageModel, setPageModel] = React.useState(defaultPageModel);
  const [filter, setFilter] =
    React.useState<GridFilterItem>(defaultFilterModel);
  const [isLoading, setIsLoading] = React.useState(false);
  const [data, setData] = React.useState({ rows: [], totalRows: 0 });
  const [density, setDensity] = React.useState<GridDensity>("compact");
  const [modalShow, setModalShow] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState<IUser | null>(null);

  const fetchData = React.useCallback(() => {
    const headers = { Authorization: getToken() };
    setIsLoading(true);
    const { pageSize, page } = pageModel;
    axiosClient
      .post(`/v1/users/${api}/${page + 1}/${pageSize}`, filter, { headers })
      .then((res) => {
        const { content, totalElements } = res.data;
        setData({ rows: content, totalRows: totalElements });
        setIsLoading(false);
      });
  }, [api, pageModel, filter]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const activateUser = (userId: number, checked: boolean) => {
    const headers = { Authorization: getToken() };
    const reqApi = `v1/users/update-is-verified/${userId}/${checked}`;
    axiosClient
      .post(reqApi, {}, { headers })
      .then((res) => {
        alert(res.data);
        fetchData();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const blockUser = (userId: number) => {
    if (window.confirm("Are you sure to block/delete this users?")) {
      const headers = { Authorization: getToken() };
      const reqApi = `v1/users/block-unblock/${userId}/${true}`;
      axiosClient
        .post(reqApi, {}, { headers })
        .then((res) => {
          alert(res.data);
          fetchData();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const getColumns = () =>
    [
      { field: "id", headerName: "Id", width: 100 },
      { field: "firstName", headerName: "First Name", width: 200 },
      { field: "gender", headerName: "Gender", width: 120 },
      { field: "username", headerName: "Contact", width: 120 },
      {
        field: "dateOfBirth",
        headerName: "Date of Birth",
        width: 150,
        renderCell: (params) => (
          <Typography>
            {moment(params.row.dateOfBirth).format("DD/MM/YYYY")}
          </Typography>
        ),
      },
      {
        field: "created_at",
        headerName: "Account Created On",
        width: 230,
        renderCell: (params) => (
          <Typography>
            {moment(params.row.created_at).format("DD/MM/YYYY, h:mm:ss a")}
          </Typography>
        ),
      },
      {
        field: "hasAndroidApp",
        headerName: "Android",
        width: 100,
        renderCell: (params) => (
          <Typography>{params.row.hasAndroidApp ? "True" : "False"}</Typography>
        ),
      },
      {
        field: "hasIosApp",
        headerName: "iOS",
        width: 100,
        renderCell: (params) => (
          <Typography>{params.row.hasIosApp ? "True" : "False"}</Typography>
        ),
      },
      {
        field: "hasWeb",
        headerName: "Web",
        width: 100,
        renderCell: (params) => (
          <Typography>{params.row.hasWeb ? "True" : "False"}</Typography>
        ),
      },
      {
        field: "onBeHalf",
        headerName: "On Behalf",
        width: 100,
        renderCell: (params) => (
          <Typography>
            {params.row?.onBehalf?.name || " "}
          </Typography>
        ),
      },
      {
        field: "memberID",
        headerName: "Member ID",
        width: 100,
        renderCell: (params) => (
          <Typography>
            {params.row?.profile?.memberId || "N/A"}
          </Typography>
        ),
      },
      {
        field: "isActive",
        headerName: "Is Active",
        width: 100,
        renderCell: (params) => (
          <Switch
            checked={params.row.profile.isVerified}
            onChange={(e) => {
              activateUser(params.row.id, e.target.checked);
            }}
          />
        ),
      },
      {
        field: "action",
        headerName: "Actions",
        width: 130,
        renderCell: (params) => (
          <React.Fragment>
            <button
              onClick={() => {
                setSelectedUser(params.row);
                setModalShow(true);
              }}
              className="btn btn-sm"
              title="Make Payment"
            >
              <i className="fa fa-money text-success"></i>
            </button>
            <button
              onClick={() => console.log(params.row)}
              className="btn btn-sm"
              title="Edit Member"
            >
              <i className="fa fa-pencil text-info"></i>
            </button>
            <button
              onClick={() => blockUser(params.row.id)}
              className="btn btn-sm"
              title="Block member"
            >
              <i className="fa fa-trash text-danger"></i>
            </button>
          </React.Fragment>
        ),
      },
    ] as GridColDef[];


  const onFilterModelChange = (filterModel: GridFilterModel) => {
    setFilter(filterModel?.items[0] || defaultFilterModel);
  };

  return (
    <Paper>
      <Typography variant="h6" component="div" sx={{ p: "10px" }}>
        {title}
      </Typography>
      <DataGrid
        density={density}
        onDensityChange={setDensity}
        pagination
        paginationMode="server"
        loading={isLoading}
        rowCount={data.totalRows}
        paginationModel={pageModel}
        pageSizeOptions={pageSizeOptions}
        rows={data.rows}
        columns={getColumns()}
        onPaginationModelChange={setPageModel}
        checkboxSelection
        disableRowSelectionOnClick
        slots={{ toolbar: CustomToolbar }}
        filterMode="server"
        onFilterModelChange={onFilterModelChange}
      />
      {selectedUser &&
        <ViewModal
          modalShow={modalShow}
          user={selectedUser}
          updateModal={(data: { modalShow: boolean }) => {
            setModalShow(data.modalShow);
            if (!data.modalShow) setSelectedUser(null);
          }}
        />
      }
    </Paper>
  );
}
