function Footer() {
  return (
    <footer className="app-footer" style={{ marginTop: "150px" }}>
      <div className="container text-center py-3">
        <small className="copyright">
          Designed with {" "}
          <i className="fa fa-heart" style={{ color: "#fb866a" }} /> by{" "}
          <a className="app-link" href="#/">
            Satz Techno
          </a>{" "}
          for developers
        </small>
      </div>
    </footer>
  );
}

export default Footer;
