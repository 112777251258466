import * as Yup from "yup";

export const CitySchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  stateId: Yup.number()
    .required("Country is required")
    .test("test-stateId", function (value) {
      if (!value) {
        return this.createError({
          message: `State is required`,
          path: "stateId",
        });
      } else return true;
    }),
  countryId: Yup.number()
    .required("Country is required")
    .test("test-countryId", function (value) {
      if (!value) {
        return this.createError({
          message: `Country is required`,
          path: "countryId",
        });
      } else return true;
    }),
});
