import {
  ISignInPayload,
  IUser,
  IVerifyTokenPayload,
} from "../../pages/sing-in/slice/types";
import { IResponse } from "../types";
import axiosClient from "./axiosClient";

const authApi = {
  signIn(payload: ISignInPayload): Promise<IResponse<IUser>> {
    const url = "/auth/sign-in";
    return axiosClient.post(url, payload);
  },
  validatetoken(payload: IVerifyTokenPayload): Promise<IResponse<IUser>> {
    const url = "/v1/users/validate-token";
    const headers = { Authorization: payload.token };
    return axiosClient.post(url, payload, { headers });
  },
};

export default authApi;
