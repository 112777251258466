import { ErrorMessage, Field, Form, Formik } from "formik";
import { Modal } from "react-bootstrap";
import { ILanguage } from "..";
import axiosClient from "../../../../../utils/api/axiosClient";
import { getToken } from "../../../../../utils/functions";
import { LanguageSchema } from "../validation";

export default function FormModel(props: any) {
  const { setLanguages, initial, setInitial, modalShow, setModalShow } = props;

  const handleSubmit = (values: ILanguage) => {
    const headers = { Authorization: getToken() };
    // create
    const formData = {
      name: values.name,
      code: values.code,
      rtl: values.rtl,
    };
    if (initial.id) {
      axiosClient
        .put("v1/languages/" + initial.id, formData, { headers })
        .then((res) => {
          if (res.status === 200) setLanguages(res.data);
        });
    } else {
      axiosClient.post("v1/languages", formData, { headers }).then((res) => {
        if (res.status === 200) setLanguages(res.data);
      });
    }
    values.name = "";
    values.code = "";
    values.rtl = false;
    setInitial({ id: 0, name: "", code: "", rtl: false });
  };

  return (
    <Modal
      show={modalShow}
      onHide={() => setModalShow(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Edit Religion
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initial}
          validationSchema={LanguageSchema}
          onSubmit={handleSubmit}
        >
          {({
            touched,
            errors,
            isSubmitting,
            values,
            handleChange,
            handleBlur,
          }) => (
            <Form className="auth-form login-form">
              <div className="mb-3">
                <label htmlFor="name">Language Name</label>
                <Field
                  id="name"
                  name="name"
                  values={initial.name}
                  placeholder="Enter Name"
                  autoComplete="off"
                  className={`mt-2 form-control ${
                    touched.name && errors.name ? "is-invalid" : ""
                  }`}
                />

                <ErrorMessage
                  component="div"
                  name="name"
                  className="invalid-feedback"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="code">Language Code</label>
                <Field
                  id="code"
                  name="code"
                  values={initial.code}
                  placeholder="Enter Code"
                  autoComplete="off"
                  className={`mt-2 form-control ${
                    touched.code && errors.code ? "is-invalid" : ""
                  }`}
                />

                <ErrorMessage
                  component="div"
                  name="code"
                  className="invalid-feedback"
                />
              </div>
              <div className="email mb-3">
                <label htmlFor="rtl">Language RTL</label>
                <select
                  name="rtl"
                  value={values.rtl ? "TRUE" : "FALSE"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`mt-2 form-control ${
                    touched.rtl && errors.rtl ? "is-invalid" : ""
                  }`}
                >
                  <option value="" label="Select a country">
                    Select RTL
                  </option>
                  <option value={"TRUE"} label={"TRUE"}>
                    TRUE
                  </option>
                  <option value={"FALSE"} label={"FALSE"}>
                    FALSE
                  </option>
                </select>

                <ErrorMessage
                  component="div"
                  name="rtl"
                  className="invalid-feedback"
                />
              </div>
              <div className="extra mt-3 row justify-content-between">
                <div className="col-6">
                  <div className="form-check">
                    <label className="form-check-label" htmlFor="isActive">
                      Is active ?
                    </label>
                    <input
                      id="isActive"
                      name="isActive"
                      className="form-check-input"
                      type="checkbox"
                      defaultValue=""
                    />
                  </div>
                </div>
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  className="btn app-btn-primary w-100 theme-btn mx-auto"
                >
                  SAVE
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}
