import { ErrorMessage, Field, Form, Formik } from "formik";
import { Modal } from "react-bootstrap";
import { IStar, initialFormData } from "..";
import axiosClient from "../../../../../utils/api/axiosClient";
import { getToken } from "../../../../../utils/functions";
import { StateSchema } from "../validation";

export default function FormModel(props: any) {
  const { setStars, setInitial, modalShow, setModalShow } = props;
  const initial = props.initial as IStar;

  const handleSubmit = (values: IStar) => {
    const headers = { Authorization: getToken() };
    const payload = {
      name: values.name,
      isActive: true,
    };
    if (initial.id) {
      axiosClient
        .put("v1/star/" + initial.id, payload, { headers })
        .then((res) => {
          if (res.status === 200) setStars(res.data);
        });
    } else {
      axiosClient.post("v1/star", payload, { headers }).then((res) => {
        if (res.status === 200) setStars(res.data);
      });
    }
    values.name = "";
    setInitial(initialFormData);
  };

  return (
    <Modal
      show={modalShow}
      onHide={() => setModalShow(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add/Edit Star
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initial}
          validationSchema={StateSchema}
          onSubmit={handleSubmit}
        >
          {({
            touched,
            errors,
            isSubmitting,
            values,
            handleChange,
            handleBlur,
          }) => (
            <Form className="auth-form login-form">
              <div className="email mb-3">
                <label htmlFor="name">Star Name</label>
                <Field
                  id="name"
                  name="name"
                  values={initial.name}
                  placeholder="Enter Name"
                  autoComplete="off"
                  className={`mt-2 form-control ${
                    touched.name && errors.name ? "is-invalid" : ""
                  }`}
                />

                <ErrorMessage
                  component="div"
                  name="name"
                  className="invalid-feedback"
                />
              </div>
              <div className="extra mt-3 row justify-content-between">
                <div className="col-6">
                  <div className="form-check">
                    <label className="form-check-label" htmlFor="status">
                      Is active ?
                    </label>
                    <input
                      id="status"
                      name="status"
                      className="form-check-input"
                      type="checkbox"
                      defaultValue=""
                    />
                  </div>
                </div>
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  className="btn app-btn-primary w-100 theme-btn mx-auto"
                >
                  SAVE
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}
