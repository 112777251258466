import { useEffect, useState } from "react";
import axiosClient from "../../../../utils/api/axiosClient";
import { getToken } from "../../../../utils/functions";
import { ICountry } from "../country";
import { IState } from "../state";
import FormModel from "./models/FormModel";

export interface ICity {
  id: number;
  name: string;
  stateId: number;
  countryId: number;
}

export const initialFormData: ICity = {
  id: 0,
  name: "",
  stateId: 0,
  countryId: 0,
};

function City() {
  const [cities, setCities] = useState<ICity[]>([]);
  const [states, setStates] = useState<IState[]>([]);
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [initial, setInitial] = useState<ICity>(initialFormData);

  useEffect(() => {
    const headers = { Authorization: getToken() };
    axiosClient.get("v1/cities", { headers }).then((res) => {
      if (res.status === 200) setCities(res.data);
    });
    axiosClient.get("v1/states", { headers }).then((res) => {
      if (res.status === 200) setStates(res.data);
    });
    axiosClient.get("v1/countries", { headers }).then((res) => {
      if (res.status === 200) setCountries(res.data);
    });
  }, []);

  const handleDelete = (id: number) => {
    const headers = { Authorization: getToken() };
    axiosClient.delete("v1/cities/" + id, { headers }).then((res) => {
      if (res.status === 200) setCities(res.data);
    });
  };

  const handleEdit = (city: ICity) => {
    setInitial(city);
    setModalShow(true);
  };

  const handleCreate = () => {
    setInitial(initialFormData);
    setModalShow(true);
  };

  const [modalShow, setModalShow] = useState(false);

  return (
    <div className="app-content pt-3 p-md-3 p-lg-4">
      <div className="container-xl">
        <h1 className="app-page-title">City</h1>
        <div className="row">
          <div className=" col-lg-12 ">
            <div className="card">
              <div className="card-header gutters-5">
                <h5 className="mb-md-0 h6">
                  All Cities
                  <i
                    onClick={handleCreate}
                    className="fa fa-plus btn btn-sm pull-right"
                  ></i>
                </h5>
              </div>
              <div className="card-body">
                <table className="table aiz-table mb-0 footable footable-1 breakpoint-lg">
                  <thead>
                    <tr className="footable-header">
                      <th style={{ display: "table-cell" }}>#</th>
                      <th style={{ display: "table-cell" }}>City Name</th>
                      <th style={{ display: "table-cell" }}>State</th>
                      <th style={{ display: "table-cell" }}>Country</th>
                      <th
                        className="text-right"
                        style={{ display: "table-cell", width: "20%" }}
                      >
                        Options
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {cities.length ? (
                      cities.map((city, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{city.name}</td>
                            <td>
                              {states.find((s) => s.id === city.stateId)?.name}
                            </td>
                            <td>
                              {
                                countries.find((c) => c.id === city.countryId)
                                  ?.name
                              }
                            </td>
                            <td>
                              <i
                                className="fa fa-pencil btn btn-sm"
                                onClick={() => handleEdit(city)}
                              ></i>
                              <i
                                className="fa fa-trash btn btn-sm"
                                onClick={() => handleDelete(city.id)}
                              ></i>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr className="footable-empty text-center">
                        <td colSpan={5}>Nothing Found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <div className="aiz-pagination"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FormModel
        {...{
          setCities,
          setStates,
          initial,
          setInitial,
          modalShow,
          setModalShow,
          countries,
          states,
        }}
      />
    </div>
  );
}

export default City;
